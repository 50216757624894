export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.donate': {
      a: {
        variant: 'buttons.primary',
        backgroundColor: 'primary',
        border: 'none',
        color: 'white !important',
        px: '1.5rem !important'
      }
    },
    '.smallNavMenu': {
      order: '3',

      padding: '0.5rem',
      '.navItem': {
        margin: '0rem 0.5rem',
        // margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          color: 'inherit',
          fontSize: ['1rem', '', '1rem', '0.85rem', '1rem'],
          fontWeight: '300',
          letterSpacing: '2px',
          padding: '0.5rem 0rem',
          display: 'flex',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.navItemLink': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        svg: {
          marginLeft: '0.25rem'
        }
      }

      // '.schedule': {
      //   a: {
      //     border: 'solid 2px',
      //     borderColor: 'white',
      //     color: 'white',
      //   },
      // },
      // '.contact': {
      //   a: {
      //     backgroundColor: 'primary',
      //     border: 'solid 2px',
      //     borderColor: 'primary',
      //     opacity: '0.8',
      //   },
      // },
    },

    // === containers ===

    '.container': {
      background: 'transparent',
      padding: ['1rem', '', '2rem 1rem', '3.5rem 1rem'],
      color: 'white',
      '.navItemDropdownMenu': {
        backgroundColor: 'transparent'
      },
      '> .phoneContainer': {
        display: 'none !important'
      },
      'div.hamburger': {
        div: {
          backgroundColor: 'white'
        }
      }
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      color: 'dark',
      padding: '0.25rem 1rem',
      borderBottom: '1px solid',
      borderColor: 'primary',
      '.navItemDropdownMenu': {
        backgroundColor: 'rgb(40 40 40 / 98%)'
      },
      '> .phoneContainer': {
        display: 'none !important'
      }
    },

    // === Hamburger ===

    '.hamburger': {
      display: ['', '', '', 'none'],
      border: 'none',
      height: '35px',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem auto',
      div: {
        backgroundColor: 'primary',
        height: '3px'
      },
      'div:nth-of-type(2)': {
        width: '70%',
        alignSelf: 'flex-start'
      }
    },
    '.hamburgerOpen': {
      position: 'fixed',
      left: ['0.25rem', '0.5rem'],
      top: ['0.5rem', '1rem'],
      width: '40px',
      height: '40px',
      filter: 'brightness(0) invert(1)'
    },

    // === phone ===
    '.container > .phoneContainer, .containerScrolled > .phoneContainer': {
      display: 'flex',
      order: '2'
    },

    // === Logo ===

    '.logoLocationContainer': {
      padding: '0rem',
      img: { filter: 'unset', maxHeight: '130px' }
    },

    // === Nav Menu ===

    '.navMenu, .navMenuOpen, .navBlock, .navBlockOpen': {
      transition: 'none',
      transform: 'none'
    },

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'light',
      width: ['85%', '60%', '50%', '40%'],
      color: 'light',
      '.navItemDropdownMenu': {
        backgroundColor: 'transparent',
        position: 'static'
      },
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '200px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.navItem': {
        a: {
          fontSize: ['0.9rem', '1rem', '1.25rem', '1.25rem'],
          color: 'text'
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'secondary'
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'text',
          svg: {
            path: { fill: 'text' }
          }
        },
        '.socialContainer': {
          flexDirection: ['', '', '', 'row']
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      display: ['flex', 'flex', '', ''],
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      '::before': {
        content: "''",
        width: '100%',
        height: '100%',
        backgroundColor: 'primary',
        position: 'absolute',
        top: '0rem',
        left: '0rem',
        opacity: '0.5'
      },
      '::after': {
        content: "'WE DO FOUNDATION'",
        fontWeight: 'bold',
        padding: ['4.5rem 1rem 1rem', '1rem', '3rem'],
        writingMode: ['vertical-rl', '', 'unset', ''],
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: ['center', 'flex-end', 'center'],
        justifyContent: ['flex-start', 'flex-end', 'flex-start'],
        position: 'absolute',
        top: '0rem',
        left: '0rem',
        boxSizing: 'border-box',
        fontSize: ['2rem', '3rem', '5rem', '6rem'],
        color: 'white'
      }
    }
  },

  footer: {
    maxWidth: 'unset',
    padding: ['3rem 1rem 7rem', '', '2rem 5rem 2rem'],
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    color: 'white',
    backgroundColor: 'primary',
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.primary',
        color: 'white',
        border: 'none'
      }
    },
    '.gonationLogo': {
      justifyContent: ['center', '', '', 'flex-end'],
      a: {
        width: 'fit-content'
      }
    },

    '.socialIconsContainer': {
      a: {
        fontWeight: '300',
        fontSize: '1rem',
        letterSpacing: '1px',
        color: 'white'
      },
      svg: {
        path: {
          fill: 'white'
        }
      }
    },

    '.contactDetails-container': {
      '.text': {
        fontWeight: '300',
        fontSize: '1rem',
        letterSpacing: '1px'
      },
      '.phone': {
        display: 'none'
      }
    },

    '.columnLogo': {}
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '2.5rem', '3.5rem', '4rem'],
    fontWeight: '900',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primary',
    order: '1'
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'heading',
    textTransform: 'capitalize'
  },
  text: {
    textAlign: 'center',
    lineHeight: '1.75',
    fontSize: '0.85rem',
    order: '3',
    p: {
      lineHeight: '1.5',
      letterSpacing: '0.5px',
      textAlign: 'left',
      fontSize: 'inherit'
    },
    'p:last-child': {
      margin: '0rem'
    }
  },

  borders: {
    '::before': {
      content: "''",
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
      backgroundSize: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: ['0rem 0rem 1.5rem', '', '0rem 0rem 3rem'],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '1rem',
      height: '50px',
      width: '100%',
      borderBottom: '2px dotted',
      borderColor: 'primary'
    },

    '::after': {
      content: "''",
      order: '6',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
      backgroundSize: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: '3rem 0rem 0rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '1rem',
      height: '50px',
      width: '100%',
      borderTop: '2px dotted',
      borderColor: 'primary'
    }
  },

  sideBySide1: {
    // variant: 'customVariants.borders',
    flexWrap: 'wrap',
    padding: ['2rem 1rem', '2rem', '', '10vh 10vw', '10vh 10vw'],
    '.lazyload-wrapper': {
      img: {
        boxShadow: '-20px -20px #2d7e74'
      }
    },
    '.content': {
      padding: ['2rem 1rem 2rem 3rem', '2rem 1rem 2rem 3rem ', '1rem', '3rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  centerBlock: {
    '.section': {
      // variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem 0rem 0rem auto',
      maxWidth: ['', '', '50%', '50%', '40%'],
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: ['2rem 0rem', '', '', '5rem'],
    margin: '0rem auto',
    // width: 'calc(100% - 15vw)',
    backgroundPosition: ['40% center', '', 'center center'],
    '.section': {
      margin: 'auto 0rem 0rem auto',
      // display: 'none',
      textAlign: 'right',
      alignItems: 'flex-end',
      maxWidth: 'unset',
      width: ['100%', '', '100%'],
      padding: ['2rem 1rem 14rem 2.5rem'],
      '> div:nth-of-type(1)': {
        order: '2'
      },
      '> div:nth-of-type(2)': {
        order: '1'
      },
      '> div:nth-of-type(3)': {
        order: '3'
      },
      a: {
        order: '4'
      },
      maxWidth: '767px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      textAlign: 'right',
      whiteSpace: 'normal'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontFamily: 'body',
      color: 'white',
      opacity: '0.9',
      letterSpacing: '2px',
      fontWeight: '200',
      textAlign: 'right',
      whiteSpace: 'normal'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'right',
      whiteSpace: 'normal',
      p: {
        color: 'white',
        whiteSpace: 'normal',
        textAlign: 'right'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '::after': {
      //   content: "'Scroll'",
      width: '0.25rem',
      textTransform: 'uppercase',
      fontSize: '0.9rem',
      textShadow: '1px 1px 10px black',
      borderLeft: 'solid 2px',
      borderColor: 'primary',
      padding: '0rem 1rem 3rem',
      wordWrap: 'break-word',
      whiteSpace: 'break-spaces',
      display: 'inline',
      bottom: '0rem',
      left: ['15%', '10%'],
      position: 'absolute',
      letterSpacing: '1px'
    }
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'white',
    flexDirection: ['column', 'column', 'column', 'column'],
    alignItems: ['center', 'center', 'center', 'center'],
    textAlign: 'center',
    '.lazyload-wrapper': {
      width: ['100%', '90%', '75%']
    },

    '.content': {
      order: '2',
      // position: 'relative',
      width: ['100%', '80%', '65%'],
      // top: ['-7.5rem', '-8.5rem', '-9.5rem', '-11.5rem', '-12.5rem'],
      // bottom: ['-7.5rem', '-8.5rem', '-9.5rem', '-11.5rem', '-12.5rem'],
      color: 'black',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '3.5rem'
    },

    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      order: '2',
      color: 'secondary'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '1',
      padding: '1rem',
      borderBottom: '4px solid',
      borderColor: 'primary'
    },
    '.text': {
      variant: 'customVariants.text',
      mb: '2rem',
      p: {
        textAlign: 'center'
      }
    },

    a: {
      order: '5',

      // marginTop: '1.5rem',
      '.ctaButton': {
        padding: '1rem',
        variant: 'buttons.secondary'
      }
    }
  },

  homepageBoxes: {
    background: 'unset !important',
    px: '0rem',
    '::before': {
      content: "''",
      display: 'flex',
      backgroundColor: 'primary',
      // backgroundImage:
      //   'url(https://res.cloudinary.com/gonation/image/upload/v1687192447/sites/We%20Do%20Foundation/waves.svg)',
      backgroundSize: '100%',
      backgroundPosition: '100% bottom',
      backgroundRepeat: 'no-repeat',
      height: '30vh',
      width: '100%',
      mb: '-3px',
      clipPath: 'polygon(0 100%, 100% 0%, 100% 100%, 0% 100%)'
    },
    '::after': {
      content: "''",
      display: 'flex',
      backgroundColor: 'primary',
      // backgroundImage:
      //   'url(https://res.cloudinary.com/gonation/image/upload/v1687192447/sites/We%20Do%20Foundation/waves.svg)',
      backgroundSize: '100%',
      backgroundPosition: '100% bottom',
      backgroundRepeat: 'no-repeat',
      height: '30vh',
      width: '100%',
      // transform: 'rotate(180deg)',
      mt: '-3px',
      clipPath: 'polygon(0 0, 100% 0%, 100% 0%, 0% 100%)'
    },
    '.react-reveal': {
      backgroundColor: 'primary'
    },
    '.box': {
      maxWidth: '380px',
      width: ['100%', '', '33.3%'],
      borderRadius: '10px',
      margin: '1rem',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      pb: 4,
      '.title': {
        mt: 2
      },
      '.subtitle': {
        color: 'primary',
        mb: 2
      },
      '.text': {
        mb: 4
      },
      '.title, .subtitle, .text': { px: 4 },
      '.image': {
        height: '300px',
        objectFit: 'cover'
      },
      '.ctaLink': {
        variant: 'buttons.secondary',
        mt: 'auto',
        mx: 'auto'
      }
    },
    '.contentBoxes': {
      justifyContent: 'center',
      backgroundColor: 'primary',
      py: '10vh'
    }
  },

  homepageSignUp: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.centerBlock',
    '.section': {
      // variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      // backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem auto 0rem 0rem',
      maxWidth: ['', '', '60%', '50%', '40%'],
      alignItems: 'flex-start',
      justifyContent: 'center',
      '.title': {
        color: 'white'
      },
      '.subtitle': {
        color: 'white',
        fontWeight: '200'
      },
      '.text': {
        variant: 'customVariants.text',
        p: { color: 'white' },
        mb: 6
      },
      a: {
        marginBottom: '10vh',
        '.ctaButton': {
          backgroundColor: 'primary',
          borderColor: 'primary'
        }
      }
    }
  },

  homepageShout: {
    '.shoutPopUpButton': {
      zIndex: '800',
      transform: 'unset',
      height: ['190px', '', 'auto'],
      width: ['70px', '', 'auto'],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      my: 'auto',
      mr: '1rem',
      svg: {
        transform: ['rotate(90deg)', '', 'unset'],
        width: ['18px', '', '2rem'],
        height: ['18px', '', '2rem'],
        display: 'flex',
        alignSelf: 'end',
        mb: ['1rem', '', '0rem'],
        position: ['absolute', '', 'static'],
        bottom: 0,
        left: 0
      }
    },
    '.image': {
      objectFit: 'contain',
      objectPosition: 'top'
    },
    '.title': {
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.25rem'],
      borderBottom: 'solid 1px',
      borderColor: 'primary'
    },
    '.text': {
      marginBottom: '2rem',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.25rem']
    },
    '.date': {
      textAlign: 'left',
      display: 'none'
    },
    '.shoutCTABtns': {
      a: {
        variant: 'buttons.secondary'
      }
    },
    '.containerPopUp': {
      border: '2px solid',
      borderColor: 'primary',
      color: 'text',
      backgroundColor: '#ffffffd6',
      zIndex: '800',
      maxWidth: '500px'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    minHeight: '50vh',
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      color: 'black',
      fontWeight: '300',
      textAlign: 'left'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      fontWeight: '300',
      textAlign: 'left',
      opacity: '0.8',
      '> *': {
        fontSize: '0.95rem',
        textAlign: 'left'
      },
      p: {
        fontSize: ['0.95rem', '', '0.95rem'],
        textAlign: 'center',
        lineHeight: '1.75'
      }
    },
    padding: ['2rem 1rem', '2rem', '3rem', '4rem'],
    '.content': {
      margin: '0rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: ['1rem', '2rem', '3rem 3rem 3rem 1rem', '4rem 4rem 4rem 2rem'],
      backgroundColor: '#f0f0f0',
      order: ['', '', '2']
    },
    '.lazyload-wrapper': {
      margin: '0rem'
    }
  },

  aboutSection2: {
    background: 'transparent',
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      color: 'black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      '> *': {
        textAlign: 'center'
      },
      p: {
        fontSize: ['1rem', '', '1.05rem'],
        textAlign: 'center',
        lineHeight: '1.75'
      }
    }
  },

  aboutTeamTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black',

    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      color: 'white',
      marginBottom: '0rem'
    }
  },

  // ? ========================
  // ? ====  sponsorship page  ====
  // ? ========================

  sponsorshipIntro: { pt: '4rem', pb: '0rem' },

  volunteerIntro: { pt: '4rem', pb: '0rem' },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '::before': {
      content: "''",
      height: '188px',
      width: '100%',
      display: 'flex',
      backgroundColor: 'primary'
    },
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    alignItems: ['', '', '', 'center'],
    textAlign: ['', '', '', 'center'],
    position: 'relative',
    zIndex: '1',
    background: 'transparent',
    '*': {
      zIndex: '3'
    },
    '.contactDetails-container': {
      textAlign: ['', '', '', 'center']
    },
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.5rem', '2.5rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'center'
    },
    backgroundPosition: 'center center',
    color: 'text',
    backgroundColor: 'background',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    label: {
      marginBottom: '0.5rem'
    },
    '.inputField, .textField, select ': {
      fontWeight: '300',
      border: '1px solid lightgrey',
      borderRadius: '0px',
      padding: '1.25rem 1rem',
      color: 'text',
      marginBottom: '1rem',

      '::placeholder': {
        color: 'text',
        fontSize: '0.8rem',
        opacity: '0.75'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto',
      backgroundColor: 'primary'
    }
  },

  // ? =======================
  // ? ====  Donate page  ====
  // ? =======================

  donateQr: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        maxWidth: '400px',
        boxShadow: 'none'
      }
    }
  }
}

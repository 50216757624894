export default {
  pageHeroV1a: {
    container: {
      height: ['45vh', '', '70vh'],
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: '1',
      overflow: 'hidden'
    },
    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        // marginBottom: 1,
        transformOrigin: 'center',
        transform: 'translate(50%, -50%) rotate(-90deg)',
        top: '50%',
        right: ['2.5rem', '', '3.5rem'],
        position: 'absolute',
        fontWeight: '300',
        fontSize: ['2rem', '', '3rem'],
        width: '100%',
        textTransform: 'uppercase'
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
